import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="w-full">
        <Header />
      </div>

      <div className="mt-8 text-[1.2rem] text-[#616161] h-[10%] p-2 w-[80%] sm:w-[50%] lg:w-[35%]  ml-[2%] sm:ml-[15%] md:ml-[5%] lg:ml-[5%] xl:ml-[5%] 2xl:ml-[15%]">
        <h3 className="font-bold pb-2 leading-snug">Πολιτική Απορρήτου</h3>
      </div>

      <div className="sm:px-[5%] md:px-[5%] lg:px-[5%] xl:px-[5%] 2xl:px-[15%]">
        <div className="p-2">
          <h5 className="font-bold pb-2">Η εταιρεία μας </h5>
          <h5 className="text-justify">
            <p className="py-2 leading-normal">
              Την 02/06/2004 καταχωρίσθηκαν στο Γενικό Εμπορικό Μητρώο (Γ.Ε.ΜΗ),
              τα κατωτέρω στοιχεία της Ανώνυμης Εταιρίας με την επωνυμία ΤΕΛΕΣΤΩ
              ΤΕΧΝΟΛΟΓΙΕΣ ΠΛΗΡΟΦΟΡΙΚΗΣ ΚΑΙ ΕΠΙΚΟΙΝΩΝΙΩΝ ΕΤΑΙΡΕΙΑ ΠΕΡΙΟΡΙΣΜΕΝΗΣ
              ΕΥΘΥΝΗΣ, το διακριτικό τίτλο ΤΕΛΕΣΤΩ ΕΠΕ και Αριθμό Γ.Ε.ΜΗ
              005776301000. Όλες οι επωνυμίες, τα σήματα και τα προϊόντα που
              αναφέρονται στην παρούσα ιστοσελίδα απολαμβάνουν των δικαιωμάτων
              των νόμιμων δικαιούχων τους.
            </p>
          </h5>

          <h5 className="font-bold mt-4">Περιορισμός ευθύνης </h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              Η Τελεστώ δεν εγγυάται την ακρίβεια, την πληρότητα ή το επίκαιρο
              οποιωνδήποτε πληροφοριών, απόψεων, συμπερασμάτων ή συστάσεων
              περιέχονται στην παρούσα ιστοσελίδα και, εξαιρουμένης της ευθύνης
              που δεν μπορεί να αποκλειστεί εκ του νόμου,
              η Τελεστώ  δεν ευθύνεται για οποιαδήποτε απώλεια ή ζημία η οποία
              μπορεί να προκληθεί άμεσα ή έμμεσα σε βάρος του επισκέπτη/χρήστη
              επειδή βασίστηκε σε οποιαδήποτε πληροφορία, άποψη, συμπέρασμα ή
              σύσταση που περιέχεται σε αυτήν την ιστοσελίδα, ανεξαρτήτως εάν
              αυτή η απώλεια ή η ζημία προκαλείται από οποιοδήποτε σφάλμα ή
              αμέλεια εκ μέρους της Τελεστώ ή όχι. Επίσης, η Τελεστώ δεν
              εγγυάται ότι η ιστοσελίδα δεν θα προσβληθεί ποτέ από ψηφιακούς
              ιούς (virus free) ή άλλα επιβλαβή στοιχεία και επομένως δεν φέρει
              ευθύνη για οποιαδήποτε τυχόν ζηµία ή βλάβη προκληθεί από ιό ή από
              οποιαδήποτε άλλη αιτία στον χρήστη κατά την επίσκεψη, χρήση ή
              περιήγησή του στην ιστοσελίδα. Η Τελεστώ διατηρεί το δικαίωμα να
              προβαίνει οποτεδήποτε σε αλλαγές ή διορθώσεις του περιεχομένου της
              παρούσας ιστοσελίδας ή να διακόψει ή αναστείλει τη λειτουργία της
              χωρίς προηγούμενη ειδοποίηση.
            </p>
          </h5>

          <h5 className="font-bold mt-4">
            Προστασία πνευματικής και βιομηχανικής ιδιοκτησίας{" "}
          </h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              Η Ιστοσελίδα περιλαμβάνει κείμενα, απεικονίσεις, λογότυπους,
              γραφικά, εικόνες, δεδομένα και προγράμματα, τα οποία,
              συμπεριλαμβανομένου και του τρόπου παρουσίασής τους,
              προστατεύονται από τις διατάξεις περί πνευματικής και βιομηχανικής
              ιδιοκτησίας και είτε ανήκουν στην αποκλειστική ιδιοκτησία
              της Τελεστώ είτε έχει παραχωρηθεί η άδεια χρήσης τους στην
              τελευταία. Το περιεχόμενο της Ιστοσελίδας δεν δύναται να
              αποτελέσει, εν όλω ή εν µέρει, αντικείμενο αναπαραγωγής,
              αναμετάδοσης ή διανομής, πώλησης ή εμπορικής εκμετάλλευσης µε
              οποιονδήποτε τρόπο από οποιονδήποτε χρήστη, χωρίς την προηγούμενη
              έγγραφη συναίνεση της Τελεστώ. Απαγορεύεται η χωρίς άδεια χρήση με
              οποιονδήποτε τρόπο των σηµάτων, λογοτύπων και διακριτικών
              γνωρισμάτων που εµφανίζονται στην Ιστοσελίδα. Ο χρήστης δεν έχει
              δικαίωμα να επιφέρει τροποποιήσεις στην Ιστοσελίδα ή να παρέμβει
              με οποιονδήποτε τρόπο στη λειτουργία της, ούτε να παραπλανήσει με
              οποιονδήποτε τρόπο το κοινό σχετικά με τον πραγματικό πάροχο του
              διαδικτυακού τόπου.
            </p>
          </h5>

          <h5 className="font-bold mt-4">
            Δεσμοί (Links) προς και από άλλες ιστοσελίδες
          </h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              Η Τελεστώ δεν φέρει καμία ευθύνη για οποιαδήποτε ιστοσελίδα η
              οποία περιέχει συνδέσμους προς ή από την παρούσα ιστοσελίδα. Η
              σύνδεση με οποιαδήποτε ιστοσελίδα αποκλειστικά με ευθύνη του
              επισκέπτη/χρήστη.
            </p>
          </h5>

          <h5 className="font-bold mt-4">Εφαρμοστέο δίκαιο και δικαιοδοσία</h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              Οι όροι και προϋποθέσεις χρήσης της παρούσας ιστοσελίδας, καθώς
              και οποιαδήποτε τροποποίηση, αλλαγή ή αλλοίωσή τους διέπονται και
              συμπληρώνονται από το ελληνικό δίκαιο. Για οποιαδήποτε διαφορά
              ανακύψει στα πλαίσια της χρήσης της παρούσας ιστοσελίδας
              αποκλειστικά αρμόδια ορίζονται τα δικαστήρια των Αθηνών και
              εφαρμοστέο το ελληνικό δίκαιο.  
            </p>
          </h5>

          <h5 className="font-bold mt-4">
            Πολιτική Προστασίας Δεδομένων Εφαρμογής Τελεστώ myCityConnect{" "}
          </h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              Τελευταία αναπροσαρμογή την 10η Μαΐου 2024
            </p>

            <p className="py-2 leading-normal">
              Καλωσήρθατε στην εφαρμογή «MyCityConnect» της ΤΕΛΕΣΤΏ ΕΠΕ, η οποία
              στη συνέχεια θα αναφέρεται ως «εφαρμογή».
            </p>

            <p className="py-2 leading-normal">
              Η Επιχείρησή μας λαμβάνει σοβαρά υπόψη την προστασία της
              ιδιωτικότητας των πελατών της. Γι’ αυτό το λόγο ακολουθούμε με
              αυστηρότητα την ακόλουθη πολιτική προστασίας, η οποία διασφαλίζει
              το υψηλό επίπεδο των προσφερόμενων υπηρεσιών και τηρεί απαρέγκλιτα
              το ισχύον νομοθετικό πλαίσιο.
            </p>

            <p className="py-2 leading-normal">
              Με την παρούσα δήλωση περί Πολιτικής Προστασίας Δεδομένων,
              επιθυμούμε να σας ενημερώσουμε για τον τρόπο που η Επιχείρησή μας
              προστατεύει το απόρρητο των επικοινωνιών σας και συλλέγει,
              επεξεργάζεται, χρησιμοποιεί και αποθηκεύει τα προσωπικά σας
              δεδομένα μέσω της εφαρμογής μας καθώς και για τις επιλογές και τα
              δικαιώματα που έχετε αναφορικά με την παραπάνω συλλογή και
              επεξεργασία των προσωπικών σας δεδομένων.
            </p>

            <p className="py-2 leading-normal">
              Με την επίσκεψη στην εφαρμογή μας λαμβάνετε γνώση και αποδέχεστε
              την πολιτική προστασίας δεδομένων, που ακολουθεί.
            </p>

            <p className="py-2 leading-normal">
              Η παρούσα Πολιτική προστασίας δεδομένων έχει ισχύ μόνο για την
              εφαρμογή. Οι χρήστες πρέπει να λαμβάνουν υπόψη, ότι η εφαρμογή μας
              ενδέχεται να περιέχει συνδέσμους και προς άλλους δικτυακούς
              τόπους, εντούτοις η Επιχείρησή μας δεν φέρει κανενός είδους ευθύνη
              για τις πρακτικές και τους όρους προστασίας δεδομένων ή το
              περιεχόμενο των εν λόγω δικτυακών τόπων.
            </p>
          </h5>

          <h5 className="font-bold mt-4">1. ΑΝΤΙΚΕΙΜΕΝΟ </h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              *Με την παρούσα πολιτική καθορίζονται οι όροι και οι προϋποθέσεις,
              που τηρούνται από την Επιχείρησή μας, για την προστασία της
              ιδιωτικότητας των χρηστών της εφαρμογής. Η πολιτική αυτή
              περιλαμβάνει τους κανόνες, με βάση τους οποίους προβαίνουμε σε
              τυχόν συλλογή και επεξεργασία των προσωπικών σας δεδομένων και
              διασφαλίζουμε την τήρηση του απορρήτου των πληροφοριών αυτών.
            </p>

            <p className="py-2 leading-normal">
              *Η Επιχείρησή μας διατηρεί το δικαίωμα να τροποποιεί και να
              αναπροσαρμόζει τη παρούσα πολιτική, όποτε κρίνει αυτό αναγκαίο,
              ενώ οι εκάστοτε αλλαγές τίθενται σε ισχύ από την δημόσια εμφάνιση
              αυτών σε αυτή την εφαρμογή.
            </p>

            <p className="py-2 leading-normal">
              *Σε περίπτωση που οποιοσδήποτε από τους παρόντες όρους θεωρηθεί
              άκυρος, παράνομος ή καταχρηστικός για οποιονδήποτε λόγο, οι λοιποί
              όροι θα παραμένουν έγκυροι και ισχυροί ως έχουν στο βαθμό που δεν
              έρχονται σε αντίθεση με την βούληση, που εκφράζεται μέσω της
              παρούσας πολιτικής.
            </p>
          </h5>

          <h5 className="font-bold mt-4">2. ΑΡΧΕΣ ΕΠΕΞΕΡΓΑΣΙΑΣ </h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              Σεβόμαστε απόλυτα τα θεμελιώδη δικαιώματά σας και ανάγουμε την
              προστασία της ιδιωτικότητάς σας σε προτεραιότητα της Επιχείρησής
              μας. Στα πλαίσια αυτά κατά την επεξεργασία των προσωπικών σας
              δεδομένων ακολουθούμε τις παρακάτω βασικές αρχές :
            </p>

            <p className="py-2 leading-normal">
              *Υποβάλλουμε τα προσωπικά σας δεδομένα σε σύννομη και θεμιτή
              επεξεργασία και διατηρούμε απέναντί σας πλήρη διαφάνεια σε σχέση
              με τον τρόπο με τον οποίο διαχειριζόμαστε τα προσωπικά σας
              δεδομένα.
            </p>

            <p className="py-2 leading-normal">
              *Συλλέγουμε και επεξεργαζόμαστε τα δεδομένα σας μόνο για
              καθορισμένους, ρητούς και νόμιμους σκοπούς, όπως αναγράφονται στην
              παρούσα πολιτική, και δεν τα υποβάλλουμε σε περαιτέρω επεξεργασία
              κατά τρόπο ασύμβατο προς τους σκοπούς αυτούς.
            </p>

            <p className="py-2 leading-normal">
              *Επεξεργαζόμαστε προσωπικά σας δεδομένα, μόνο στον βαθμό που είναι
              κατάλληλα και συναφή με τους παραπάνω σκοπούς, περιορίζοντας
              ταυτόχρονα την σχετική επεξεργασία στο αναγκαίο για τους σκοπούς
              αυτούς μέτρο.
            </p>

            <p className="py-2 leading-normal">
              *Καταβάλλουμε εύλογες προσπάθειες και με την δική σας συνδρομή,
              ώστε τα υπό επεξεργασία δεδομένα σας να είναι ακριβή και, όταν
              είναι αναγκαίο, να επικαιροποιούνται σε σχέση με τους σκοπούς της
              επεξεργασίας, λαμβάνοντας όλα τα εύλογα μέτρα για την άμεση
              διαγραφή ή διόρθωσή τους σε περίπτωση ανακρίβειας
            </p>

            <p className="py-2 leading-normal">
              *Διατηρούμε τα προσωπικά σας δεδομένα υπό μορφή που επιτρέπει την
              ταυτοποίησή σας μόνο για το διάστημα που απαιτείται για τους
              παραπάνω σκοπούς επεξεργασίας.
            </p>

            <p className="py-2 leading-normal">
              *Υποβάλλουμε τα προσωπικά σας δεδομένα σε επεξεργασία κατά τρόπο
              που εγγυάται την ασφάλειά τους με τη χρησιμοποίηση κατάλληλων
              τεχνικών ή οργανωτικών μέτρων.
            </p>

            <p className="py-2 leading-normal">
              *Δεν προτιθέμεθα να επεξεργαστούμε περαιτέρω τα προσωπικά σας
              δεδομένα για σκοπό άλλον από εκείνο για τον οποίο αυτά
              συλλέχθηκαν.
            </p>

            <p className="py-2 leading-normal">
              *Σας ενημερώνουμε πως δεν υφίσταται υποχρέωσή σας για την παροχή
              των προσωπικών σας δεδομένων και δεν υφίστανται ενδεχόμενες
              συνέπειες από την μη παροχή αυτών. Με την επιφύλαξη των όσων
              αναφέρονται στην παρούσα πολιτική δεν γνωστοποιούμε και δεν
              διαβιβάζουμε τα προσωπικά σας δεδομένα σε τρίτα περαιτέρω πρόσωπα
              χωρίς τη δική σας συγκατάθεση, εκτός εάν αυτό επιτρέπεται από το
              νόμο ή από τη μεταξύ μας σύμβαση.
            </p>

            <p className="py-2 leading-normal">
              *Σας ενημερώνουμε πως δεν διαβιβάζουμε προσωπικά σας δεδομένα σε
              τρίτη χώρα ή διεθνή οργανισμό, για τα οποία δεν υφίσταται απόφαση
              επάρκειας της Ευρωπαϊκής Επιτροπής σύμφωνα με τον ΓΚΠΔ.
            </p>

            <p className="py-2 leading-normal">
              *Εν γένει τηρούμε στο ακέραιο την κείμενη νομοθεσία και
              συμμορφωνόμαστε με όλες τις υποχρεώσεις μας που απορρέουν από
              αυτή, ως κατά τον νόμο υπεύθυνος επεξεργασίας των προσωπικών σας
              δεδομένων.
            </p>
          </h5>

          <h5 className="font-bold mt-4">3. ΕΙΔΗ ΔΕΔΟΜΕΝΩΝ </h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              *Τα είδη των προσωπικών δεδομένων, που συλλέγουμε και
              επεξεργαζόμαστε από εσάς, εξαρτώνται από τις υπηρεσίες, που
              επιλέγετε να σας παρέχουμε. Έτσι, κατά (α) την είσοδο και χρήση
              της εφαρμογής, και (β) την παροχή υπηρεσιών, παραχωρείτε στην
              Επιχείρησή μας τα ακόλουθα είδη προσωπικών δεδομένων.
            </p>

            <p className="py-2 leading-normal">
              *Κατά την πλοήγηση στην εφαρμογή μας παρέχετε τα εξής δεδομένα:
            </p>

            <p className="py-2 leading-normal">
              *Στοιχεία ταυτοποίησης, δηλαδή όνομα, επίθετο, καθώς και κωδικό
              για την εξουσιοδότηση πρόσβασης
            </p>

            <p className="py-2 leading-normal">
              *Στοιχεία επικοινωνίας σας, συγκεκριμένα ΤΚ, αριθμό κινητού
              τηλεφώνου, emai
            </p>

            <p className="py-2 leading-normal">
              *Στατιστικά και Δεδομένα πλοήγησης εντός της εφαρμογής για την
              άντληση analytics.
            </p>

            <p className="py-2 leading-normal">
              *Πληροφορίες καταχωρούμενων προβλημάτων και παραπόνων.
            </p>

            <p className="py-2 leading-normal">
              *Περιεχόμενο παραγόμενο από τον Χρήστη, ενδεικτικά κείμενο και
              εικόνες που αφορούν τεχνικό πρόβλημα.
            </p>

            <p className="py-2 leading-normal">
              *Επιπλέον, σε περίπτωση που οποιοσδήποτε χρήστης επικοινωνεί μαζί
              μας μέσω ηλεκτρονικού ταχυδρομείου ή με άλλα μέσα, προβαίνουμε σε
              συλλογή και επεξεργασία προσωπικών δεδομένων, που σχετίζονται με
              τέτοιου είδους επικοινωνίες, υπό τους όρους και τις προϋποθέσεις
              της παρούσας, προκειμένου να ανταποκρινόμαστε στις σχετικές
              απαιτήσεις και αιτήματα και να βελτιώνουμε τις προσφερόμενες
              υπηρεσίες μας.
            </p>

            <p className="py-2 leading-normal">
              *Κατά τη χρήση της εφαρμογής και όταν ο χρήστης ζητήσει γεωγραφική
              πληροφορία («πλησιέστερος μεταπωλητής»), η εφαρμογή μπορεί να
              ζητήσει και ο χρήστης να δώσει πρόσβαση στην τοποθεσία του
            </p>

            <p className="py-2 leading-normal">
              *Η Επιχείρησή μας δεν συλλέγει ούτε αποκτά πρόσβαση με κανέναν
              τρόπο σε ειδικές κατηγορίες («ευαίσθητων») προσωπικών δεδομένων ή
              δεδομένων που αφορούν ποινικές καταδίκες και αδικήματα χρηστών –
              πελατών της. Έχετε την υποχρέωση να απέχετε από την ανάρτηση
              τέτοιων δεδομένων, που αφορούν το πρόσωπό σας ή τρίτα υποκείμενα
              δεδομένων. Σε περίπτωση που προβείτε σε σχετική ανάρτηση τέτοιων
              δεδομένων στην εφαρμογή, αυτά θα αφαιρούνται μόλις υποπίπτουν στην
              αντίληψή μας. Δεν φέρουμε οποιαδήποτε ευθύνη απέναντί σε εσάς ή σε
              τρίτα μέρη για τυχόν ανάρτηση ή / και επεξεργασία ευαίσθητων
              δεδομένων, που οφείλεται σε πράξεις ή παραλείψεις σας κατά
              παράβαση της παραπάνω υποχρέωσης.
            </p>
          </h5>

          <h5 className="font-bold mt-4">
            4. ΣΚΟΠΟΙ ΚΑΙ ΝΟΜΙΚΗ ΒΑΣΗ ΕΠΕΞΕΡΓΑΣΙΑΣ{" "}
          </h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              *Προσωπικά σας δεδομένα, που είναι απαραίτητα για την πλοήγηση και
              χρήση της εφαρμογής σύμφωνα με το άρθρο 6 § 1 (β) του ΓΚΠΔ,
              συλλέγονται και τυγχάνουν επεξεργασίας από την Επιχείρησή μας για
              τους ακόλουθους σκοπούς:
            </p>

            <p className="py-2 leading-normal">
              *Τεχνική δυνατότητα για την απρόσκοπτη λειτουργία της εφαρμογής.
            </p>

            <p className="py-2 leading-normal">
              *Φιλική και εύκολη προς τον Χρήστη λειτουργία της εφαρμογής
            </p>

            <p className="py-2 leading-normal">
              *Βελτίωση της διαδικτυακής σας εμπειρίας κατά την πλοήγηση και
              χρήση της εφαρμογής
            </p>

            <p className="py-2 leading-normal">
              *Καταγραφή παραπόνων και προβλημάτων μέσω της χρήσης ανώνυμων
              στατιστικών δεδομένων
            </p>

            <p className="py-2 leading-normal">
              *Προσωπικά σας δεδομένα, που είναι απαραίτητα για την παροχή των
              υπηρεσιών μας στα πλαίσια μεταξύ μας συμβατικής σχέσης σύμφωνα με
              το άρθρο 6 § 1 (β) του ΓΚΠΔ, συλλέγονται και τυγχάνουν
              επεξεργασίας από την Επιχείρησή μας για τους ακόλουθους σκοπούς :
            </p>

            <p className="py-2 leading-normal">
              *Εκτέλεση των συμβατικών υποχρεώσεων της Επιχείρησής μας απέναντι
              στους απλούς και Επιχειρηματικούς Χρήστες της εφαρμογής.
            </p>

            <p className="py-2 leading-normal">
              *Άμεση, επαρκής και αποτελεσματική παροχή των υπηρεσιών μας.
            </p>

            <p className="py-2 leading-normal">
              *Επικοινωνία με πελάτες μας στα πλαίσια της καλής εκτέλεσης των
              υπηρεσιών μας καθώς και για την επίλυση τυχόν παραπόνων.
            </p>

            <p className="py-2 leading-normal">
              *Βελτίωση και ανανέωση των προϊόντων και των υπηρεσιών μας, ώστε
              να ανταποκρίνονται όσο το δυνατόν περισσότερο στις ανάγκες των
              πελατών μας.
            </p>

            <p className="py-2 leading-normal">
              *Διοικητική οργάνωση και λειτουργία της Επιχείρησής μας.
            </p>

            <p className="py-2 leading-normal">
              *Διαχείριση του πελατολογίου μας.
            </p>

            <p className="py-2 leading-normal">*Υποστήριξη νομικών αξιώσεων.</p>

            <p className="py-2 leading-normal">
              *Η Επιχείρησή μας συλλέγει και επεξεργάζεται προσωπικά δεδομένα
              χρηστών – πελατών της μόνο για τους προαναφερόμενους σκοπούς και
              μόνο στο αναγκαίο μέτρο για την αποτελεσματική εξυπηρέτηση των
              σκοπών αυτών. Τα δεδομένα αυτά είναι κάθε φορά συναφή, πρόσφορα
              και όχι περισσότερα από όσα απαιτούνται εν όψει των ανωτέρω
              σκοπών, είναι δε ακριβή και, εφόσον χρειάζεται, υποβάλλονται σε
              ενημέρωση. Περαιτέρω, τα εν λόγω δεδομένα διατηρούνται μόνο κατά
              τη διάρκεια της περιόδου, που απαιτείται, για την πραγματοποίηση
              των σκοπών της συλλογής και επεξεργασίας τους και διαγράφονται
              μετά το πέρας αυτής.{" "}
            </p>
          </h5>

          <h5 className="font-bold mt-4">5. ΣΥΓΚΑΤΑΘΕΣΗ </h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              *Η Επιχείρησή μας δύναται να προβαίνει σε επεξεργασία προσωπικών
              δεδομένων μόνο κατόπιν νόμιμης συγκατάθεσης των χρηστών της
              εφαρμογής για τους ακόλουθους σκοπούς :
            </p>

            <p className="py-2 leading-normal">
              *Για σκοπούς εμπορικής επικοινωνίας, εμπορικής προώθησης και
              διαφήμισης των υπηρεσιών μας ή υπηρεσιών τρίτων μερών μέσω SMS,
              τηλεφώνου, ηλεκτρονικού ταχυδρομείου, διαδικτύου, φαξ,
              αλληλογραφίας, κοινωνικών μέσων ή/και οποιωνδήποτε άλλων
              κατάλληλων διαύλων επικοινωνίας.
            </p>

            <p className="py-2 leading-normal">
              *Για σκοπούς έρευνας ή/και ανάλυσης για την καλύτερη κατανόηση των
              αναγκών, των προτιμήσεων, των συμφερόντων, των εμπειριών ή/και των
              συνηθειών σας ως καταναλωτή.
            </p>

            <p className="py-2 leading-normal">
              *Για τη λειτουργία και διαχείριση οποιωνδήποτε προγραμμάτων
              επιβράβευσης.
            </p>

            <p className="py-2 leading-normal">
              *Μας παρέχετε τη συγκατάθεσή σας για την επεξεργασία των
              προσωπικών σας δεδομένων για τους παραπάνω σκοπούς με ηλεκτρονική
              δήλωση κατά τρόπο σαφώς διακριτό από άλλα θέματα, σε κατανοητή και
              εύκολα προσβάσιμη μορφή. Η συγκατάθεσή σας δίνεται ελεύθερα και τα
              προσωπικά σας δεδομένα παρέχονται, χωρίς τέτοια παροχή να αποτελεί
              νομική ή συμβατική σας υποχρέωση ή απαίτηση της εταιρείας μας για
              τη σύναψη μεταξύ μας σύμβασης.
            </p>

            <p className="py-2 leading-normal">
              *Έχετε δικαίωμα να ανακαλέσετε τη συγκατάθεσή σας ανά πάσα στιγμή.
              Η ανάκληση της συγκατάθεσης δεν θίγει τη νομιμότητα της
              επεξεργασίας που βασίστηκε στη συγκατάθεση προ της ανάκλησής της.
              Η ανάκληση της συγκατάθεσής σας γίνεται ομοίως με την παροχή της.
            </p>
          </h5>

          <h5 className="font-bold mt-4">6. ΤΡΙΤΟΙ ΑΠΟΔΕΚΤΕΣ ΤΩΝ ΔΕΔΟΜΕΝΩΝ </h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              *Η Επιχείρησή μας δεν προβαίνει σε παραχώρησή των προσωπικών σας
              δεδομένων ή σε διασύνδεση του αρχείου της έναντι οικονομικού ή
              άλλου ανταλλάγματος με οποιεσδήποτε τρίτες ιδιωτικές επιχειρήσεις,
              φυσικά ή νομικά πρόσωπα, δημόσιες αρχές ή υπηρεσίες ή άλλους
              οργανισμούς.
            </p>

            <p className="py-2 leading-normal">
              *Για την εξυπηρέτηση των σκοπών επεξεργασίας, που αναφέρονται στην
              παρούσα πολιτική, η Επιχείρησή μας ενδέχεται να παρέχει πρόσβαση ή
              να διαβιβάζει τα εξής είδη προσωπικών σας δεδομένα στους
              ακόλουθους εκτελούντες την επεξεργασία για λογαριασμό και κατ’
              εντολή της :
            </p>

            <p className="py-2 leading-normal">
              *Προσωπικά σας δεδομένα στον πάροχο υπηρεσιών διαδικτυακής
              φιλοξενίας, με τον οποίο διατηρούμε συμβατική σχέση.
            </p>

            <p className="py-2 leading-normal">
              *Προσωπικά σας δεδομένα στην επιχείρηση, που μας παρέχει υπηρεσίες
              συντήρησης και υποστήριξης προγραμμάτων λογισμικού και βάσεων
              δεδομένων, με σκοπό την υποστήριξη της εφαρμογής.
            </p>

            <p className="py-2 leading-normal">
              *Δεδομένα προτιμήσεων και στοιχεία επικοινωνίας σε τρίτες
              επιχειρήσεις μάρκετινγκ και διαφήμισης για την εμπορική
              επικοινωνία, εμπορική προώθηση και διαφήμιση των υπηρεσιών μας ή
              υπηρεσιών τρίτων μερών.
            </p>

            <p className="py-2 leading-normal">
              *Προσωπικά σας δεδομένα σε τρίτες επιχειρήσεις συμβούλων για την
              παροχή υπηρεσιών ανάλυσης δεδομένων.
            </p>

            <p className="py-2 leading-normal">
              *Η επεξεργασία των προσωπικών σας δεδομένων από τους παραπάνω
              συνεργαζόμενους με εμάς φορείς διεξάγεται υπό τον έλεγχό μας και
              μόνον κατ’ εντολή μας και υπόκειται στην ίδια πολιτική προστασίας
              δεδομένων ή σε πολιτική του ίδιου τουλάχιστον επιπέδου προστασίας.
            </p>

            <p className="py-2 leading-normal">
              *Σε περίπτωση που υποχρεωθεί από δικαστήριο ή άλλη διοικητική αρχή
              καθώς και σε κάθε άλλη περίπτωση που φέρει έννομη υποχρέωση προς
              τούτο, η Επιχείρησή μας ενδέχεται να παραχωρήσει προσωπικά σας
              δεδομένα μέχρι της έκτασης που προβλέπεται από τον νόμο κατόπιν
              όμως ενημέρωσής σας.
            </p>
          </h5>

          <h5 className="font-bold mt-4">
            7. ΑΠΟΡΡΗΤΟ ΚΑΙ ΑΣΦΑΛΕΙΑ ΔΕΔΟΜΕΝΩΝ{" "}
          </h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              *Η Επιχείρησή μας, προκειμένου να διασφαλίσει την ορθή χρήση και
              την ακεραιότητα των προσωπικών σας δεδομένων, καθώς και να
              αποτρέψει μη εξουσιοδοτημένη ή τυχαία πρόσβαση, επεξεργασία,
              διαγραφή, αλλοίωση ή άλλη χρήση αυτών, εφαρμόζει κατάλληλες
              εσωτερικές πολιτικές, ενώ λαμβάνει όλα τα κατάλληλα οργανωτικά,
              τεχνικά, φυσικά, ηλεκτρονικά και διαδικαστικά μέτρα ασφάλειας,
              καθώς και τεχνολογικά πρότυπα, σύμφωνα με τους ισχύοντες νόμους
              και κανονισμούς.
            </p>

            <p className="py-2 leading-normal">
              *Η διαδικασία επεξεργασίας δεδομένων από την Επιχείρησή μας
              διεξάγεται με τρόπο που διασφαλίζει το απόρρητο και την φυσική και
              λογική ασφάλεια αυτών, λαμβάνοντας υπόψη τις τελευταίες εξελίξεις,
              το κόστος εφαρμογής και τη φύση, το πεδίο εφαρμογής, το πλαίσιο
              και τους σκοπούς της επεξεργασίας, καθώς και τους κινδύνους
              διαφορετικής πιθανότητας επέλευσης και σοβαρότητας για τα
              δικαιώματα και τις ελευθερίες σας.
            </p>

            <p className="py-2 leading-normal">
              *Η επεξεργασία των προσωπικών σας δεδομένων διεξάγεται
              αποκλειστικά και μόνο από εντεταλμένο για αυτόν το σκοπό
              προσωπικό, που δεσμεύεται από αυστηρές υποχρεώσεις για την τήρηση
              της εμπιστευτικότητάς τους.
            </p>
          </h5>

          <h5 className="font-bold mt-4">8. ΧΡΟΝΟΣ ΔΙΑΤΗΡΗΣΗΣ ΔΕΔΟΜΕΝΩΝ </h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              *Διατηρούμε τα προσωπικά σας δεδομένα για όσο διάστημα παραμένουν
              σε ισχύ οι σκοποί, για τους οποίους συλλέχθηκαν και αναφέρονται
              παραπάνω
            </p>

            <p className="py-2 leading-normal">
              *Η Επιχείρησή μας ενδέχεται να διατηρεί προσωπικά σας δεδομένα και
              μετά την εκπλήρωση των σκοπών συλλογής και επεξεργασίας αυτών στις
              ακόλουθες περιοριστικά αναφερόμενες περιπτώσεις:
            </p>

            <p className="py-2 leading-normal">
              *Εφόσον υφίσταται έννομη υποχρέωσή μας από σχετική διάταξη νόμου
            </p>

            <p className="py-2 leading-normal">
              *Για την χρήση ενώπιον φορολογικών και κοινωνικοασφαλιστικών αρχών
              καθώς και κάθε άλλης ελεγκτικής αρχής στα πλαίσια της νόμιμης
              προθεσμίας παραγραφής.
            </p>

            <p className="py-2 leading-normal">
              *Εφόσον απαιτείται για την καλή οργάνωση και λειτουργία της
              Επιχείρησής μας υπό τον όρο ότι λαμβάνει χώρα ανωνυμοποίηση ή
              ψευδωνυμοποίηση των δεδομένων σας.
            </p>

            <p className="py-2 leading-normal">
              *Μέχρι την παραγραφή των σχετικών αξιώσεων για την προάσπιση των
              δικαιωμάτων και των έννομων συμφερόντων μας ενώπιον παντός
              αρμόδιου Δικαστηρίου και κάθε άλλης δημόσιας αρχής
            </p>

            <p className="py-2 leading-normal">
              *Μετά το πέρας του χρόνου διατήρησης τα προσωπικά σας δεδομένα
              καταστρέφονται από τα αρχεία και το σύστημά μας σε συμμόρφωση με
              την πολιτική της επιχείρησής μας και υπό την προϋπόθεση ότι η
              διατήρησή τους δεν απαιτείται πλέον για την εκπλήρωση των σκοπών,
              που σας έχουμε περιγράψει παραπάνω.
            </p>
          </h5>

          <h5 className="font-bold mt-4">9. ΔΙΚΑΙΩΜΑΤΑ </h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              *Με την επιφύλαξη της ισχύουσας νομοθεσίας έχετε τα ακόλουθα
              δικαιώματα :
            </p>

            <p className="py-2 leading-normal">
              *Να ζητήσετε πρόσβαση στα προσωπικά σας δεδομένα καθώς και στις
              σχετικές με την επεξεργασία πληροφορίες και να λάβετε αντίγραφο
              αυτών.
            </p>

            <p className="py-2 leading-normal">
              *Να ζητήσετε την διόρθωση τυχόν ανακριβών και την συμπλήρωση τυχόν
              ελλιπών προσωπικών σας δεδομένων
            </p>

            <p className="py-2 leading-normal">
              *Να ζητήσετε την διαγραφή των προσωπικών σας δεδομένων.
            </p>

            <p className="py-2 leading-normal">
              *Να ζητήσετε τον περιορισμό της επεξεργασίας των προσωπικών σας
              δεδομένων για τις περιπτώσεις που ορίζει ρητά ο νόμος.
            </p>

            <p className="py-2 leading-normal">
              *Να ζητήσετε την φορητότητα των προσωπικών σας δεδομένων σε άλλον
              υπεύθυνο επεξεργασίας σε δομημένο, κοινώς χρησιμοποιούμενο και
              αναγνώσιμο από μηχανήματα μορφότυπο (πχ cd).
            </p>

            <p className="py-2 leading-normal">
              *Να εναντιωθείτε στην επεξεργασία των προσωπικών σας δεδομένων για
              τις περιπτώσεις που ορίζει ρητά ο νόμος
            </p>

            <p className="py-2 leading-normal">
              *Να μην υποβάλλεστε σε απόφαση που λαμβάνεται αποκλειστικά βάσει
              αυτοματοποιημένης επεξεργασίας, συμπεριλαμβανομένης της κατάρτισης
              προφίλ, η οποία παράγει έννομα αποτελέσματα που σας αφορούν ή σας
              επηρεάζει σημαντικά με παρόμοιο τρόπο. Τα παραπάνω αιτήματα
              απευθύνονται γραπτώς με ταχυδρομική επιστολή στην έδρα της
              Επιχείρησης μας.
            </p>

            <p className="py-2 leading-normal">
              *Η Επιχείρησή μας θα ανταποκρίνεται σε κάθε αίτημά σας εντός μηνός
              από την παραλαβή του. Κατόπιν ενημέρωσής σας, η εν λόγω προθεσμία
              δύναται να παρατείνεται κατά δύο ακόμη μήνες, εφόσον απαιτείται,
              λαμβανομένων υπόψη της πολυπλοκότητας του αιτήματος και του
              αριθμού των αιτημάτων. Τυχόν απόρριψη του αιτήματός σας θα
              λαμβάνει χώρα αιτιολογημένα.
            </p>

            <p className="py-2 leading-normal">
              *Εάν τα αιτήματα σας δεν πληρούν τις προϋποθέσεις του νόμου, η
              Επιχείρησή μας διατηρεί το δικαίωμα, είτε : (α) να επιβάλει την
              καταβολή εύλογου τέλους, λαμβάνοντας υπόψη τα διοικητικά έξοδα για
              την παροχή της ενημέρωσης ή την ανακοίνωση ή την εκτέλεση της
              ζητούμενης ενέργειας, είτε (β) να αρνηθεί να δώσει συνέχεια στο
              σχετικό αίτημά σας.
            </p>

            <p className="py-2 leading-normal">
              *Σε περίπτωση παραβίασης των προσωπικών σας δεδομένων, η οποία
              ενδέχεται να θέσει σε υψηλό κίνδυνο τα δικαιώματα και τις
              ελευθερίες σας και υπό την προϋπόθεση ότι αυτή δεν εμπίπτει σε μία
              από τις εξαιρέσεις που προβλέπει ρητά ο νόμος, αναλαμβάνουμε την
              υποχρέωση να σας ενημερώσουμε σχετικώς χωρίς αδικαιολόγητη
              καθυστέρηση.
            </p>

            <p className="py-2 leading-normal">
              *Εφόσον υπάρχουν αμφιβολίες σχετικά με την ταυτότητα του φυσικού
              προσώπου που υποβάλλει το αίτημα, διατηρούμε το δικαίωμα να
              ζητήσουμε την παροχή πρόσθετων πληροφοριών αναγκαίων για την
              επιβεβαίωση της ταυτότητάς του.
            </p>

            <p className="py-2 leading-normal">
              *Εφόσον παραβιάζονται δικαιώματά σας, σας ενημερώνουμε ότι έχετε
              το δικαίωμα υποβολής καταγγελίας στην Αρχή Προστασίας Δεδομένων
              Προσωπικού Χαρακτήρα ή σε άλλη αρμόδια εποπτική αρχή.
            </p>
          </h5>

          <h5 className="font-bold mt-4">10. ΥΠΟΧΡΕΩΣΕΙΣ ΧΡΗΣΤΩΝ </h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              *Με την χρήση της εφαρμογής καθώς και κατά την παραχώρηση
              προσωπικών σας δεδομένων κατόπιν συγκατάθεσης αναγνωρίζετε ότι
              έχετε την υποχρέωση να δηλώνετε τα πραγματικά, ακριβή και πλήρη
              στοιχεία που σας ζητούνται από την Επιχείρησή μας. Περαιτέρω,
              οφείλετε να ενημερώνετε την Επιχείρησή μας για τυχόν αλλαγές στα
              στοιχεία αυτά, προκειμένου να παραμένουν επικαιροποιημένα και
              ακριβή.
            </p>

            <p className="py-2 leading-normal">
              *Αναγνωρίζετε ότι η Επιχείρησή μας δύναται να διαγράψει,
              διασταυρώσει, συμπληρώσει ή να τροποποιήσει τις πληροφορίες που
              παρέχετε με βάση πληροφορίες που παρέχονται νομίμως από τρίτα
              μέρη. Στην περίπτωση αυτή η Επιχείρησή μας θα προβεί σε σχετική
              ενημέρωσή σας, τηρώντας την νόμιμη διαδικασία.
            </p>

            <p className="py-2 leading-normal">
              *Με την χρήση της εφαρμογής βεβαιώνετε πως είστε άνω των δέκα έξι
              (16) ετών. Εφόσον είστε κάτω των δέκα έξι (16) ετών έχετε την
              υποχρέωση να απέχετε από οποιαδήποτε χρήση της εφαρμογής καθώς και
              από οποιαδήποτε παραχώρηση προσωπικών σας δεδομένων χωρίς την
              έγκριση του προσώπου, που ασκεί την γονική σας μέριμνα. Εφόσον δεν
              τηρείτε τις παραπάνω υποχρεώσεις, οφείλετε να προβείτε σε άμεση
              σχετική γνωστοποίηση στην επιχείρησή μας. Σε κάθε περίπτωση, με
              την χρήση της εφαρμογής αναγνωρίζετε πως η Επιχείρησή μας δεν
              φέρει ευθύνη για την παραβίαση των παραπάνω υποχρεώσεων από μέρους
              σας στο μέτρο που δεν έχει την δυνατότητα, ακόμη και αν καταβάλλει
              εύλογες προσπάθειες, για την επαλήθευση της ηλικίας σας ή για την
              συγκατάθεση του ασκούντος την γονική σας μέριμνα.
            </p>
          </h5>

          <h5 className="font-bold mt-4">
            11. ΔΙΕΘΝΗΣ ΔΙΚΑΙΟΔΟΣΙΑ ΚΑΙ ΕΦΑΡΜΟΣΤΕΟ ΔΙΚΑΙΟ{" "}
          </h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              *Για την επίλυση οποιασδήποτε διαφοράς προκύπτει μεταξύ της
              Επιχείρησής μας και χρηστών της εφαρμογής σχετικά με την παρούσα
              πολιτική προστασίας δεδομένων και το αντικείμενο αυτής, αρμόδια
              είναι τα δικαστήρια των Αθηνών και εφαρμοστέο δίκαιο το Ελληνικό,
              χωρίς να λαμβάνονται υπόψη οι κανόνες σύγκρουσής του.
            </p>

            <p className="py-2 leading-normal">
              *Σε περίπτωση που διάταξη της παρούσας πολιτικής ακυρωθεί με
              απόφαση αρμοδίου δικαστηρίου ως μη νόμιμη, έγκυρη και εκτελεστή,
              το γεγονός αυτό δε θα επηρεάζει το υπόλοιπο των διατάξεών της, οι
              οποίες θα παραμένουν σε ισχύ και θα εφαρμόζονται κανονικά.
            </p>
          </h5>

          <h5 className="font-bold mt-4">12. ΕΠΙΚΟΙΝΩΝΙΑ </h5>
          <h5 className="text-justify mt-2">
            <p className="py-2 leading-normal">
              Για οποιαδήποτε περαιτέρω πληροφορία ή αίτημα σχετικά με την
              παρούσα πολιτική προστασίας δεδομένων μπορείτε να απευθύνεστε στον
              υπεύθυνο προστασίας δεδομένων της Επιχείρησής μας στα ακόλουθα
              στοιχεία :
            </p>

            <p className="py-2 leading-normal">*Οδός Υμηττού 62, Χολαργός</p>

            <p className="py-2 leading-normal">*ΤΚ 151 24</p>

            <p className="py-2 leading-normal">*Ελλάδα</p>

            <p className="py-2 leading-normal">*email: privacy@telesto.gr </p>
          </h5>
        </div>
      </div>

      <div className="mt-2">
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
