import { useState } from "react";
import { Link as ScrollLink } from "react-scroll";

import { Link, useNavigate } from "react-router-dom";
import Logo from "../../Assets/images/logo.png";
import Cross from "../../Assets/images/cross-icon.svg";

import "./header.css";

const Header = (props) => {
  const [toggleClass, setToggleClass] = useState("navigation");

  const navigate = useNavigate();

  const handleClick = (to) => {
    navigate("/");
    setTimeout(() => {
      document.getElementById(to).scrollIntoView({ behavior: "smooth" });
    }, 1);
  };

  return (
    <header className={props.headerClass}>
      <div className="container-fluid">
        <div className="header-row">
          <div className="logo-box">
            {/* <Link to={"/"}>
              <img src={Logo} alt="" />
            </Link> */}
          </div>
          <div className={toggleClass}>
            <button
              className="cross-btn"
              onClick={() => setToggleClass("navigation")}
            >
              <img src={Cross} alt="" />
            </button>
            <ul className="main-menu">
              {/* <li>
                <ScrollLink
                  to="service-section"
                  onClick={() => handleClick("service-section")}
                  smooth
                  duration={500}
                >
                  Ψηφιακές Υπηρεσίες
                </ScrollLink>
              </li> */}
              {/* <li>
                <ScrollLink
                  to="download-app"
                  onClick={() => handleClick("download-app")}
                  smooth
                  duration={500}
                >
                  Εφαρμογές
                </ScrollLink>
              </li> */}
              <li>
                <ScrollLink
                  to="urban-service"
                  onClick={() => handleClick("urban-service")}
                  smooth
                  duration={500}
                >
                  UrbanConnect
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="opinions-section"
                  onClick={() => handleClick("opinions-section")}
                  smooth
                  duration={500}
                >
                  Απόψεις
                </ScrollLink>
              </li>
              {/* <li>
                <ScrollLink
                  to="news-section"
                  onClick={() => handleClick("news-section")}
                  smooth
                  duration={500}
                >
                  Νέα
                </ScrollLink>
              </li> */}
              <li>
                <ScrollLink
                  onClick={() => {
                    navigate("/privacy-policy");
                  }}
                  smooth
                  duration={500}
                >
                  Όροι Χρήσης
                </ScrollLink>
              </li>
            </ul>
          </div>
          <div className="toggle-btn">
            <button onClick={() => setToggleClass("navigation show-menu")}>
              <i className="fa fa-bars" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
