// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header */
.header-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.logo-box a {
  display: block;
}

.logo-box a img {
  width: 100%;
  max-width: 109px;
}
.main-menu {
  display: flex;
  flex-wrap: wrap;
}

.main-menu li a {
  font-size: 17.6px;
  font-weight: 600;
  margin: 0 12px 0;
  color: #666666d9;
}
.main-menu li {
  border-right: 1px solid rgb(0 0 0 / 10%);
  line-height: 18px;
}

a {
  cursor: pointer;
}
.main-menu li:last-child {
  border-right: none;
}
.position-header .main-menu li a {
  color: #fff;
}
header {
  padding: 13px 0;
  border-bottom: 1px solid rgb(34 34 34 / 10%);
}
.position-header {
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  margin-top: 5px;
  border-bottom: none;
}
header .container-fluid {
  padding: 0 50px;
}
.toggle-btn button {
  background-color: transparent;
  border: none;
  color: #222;
  font-size: 25px;
}
.position-header .toggle-btn button {
  color: #fff;
}
.cross-btn {
  background-color: transparent;
  border: none;
  color: #666666;
  font-size: 25px;
  display: none;
}

.toggle-btn {
  display: none;
}
/* End Header */
`, "",{"version":3,"sources":["webpack://./src/Components/Header/header.css"],"names":[],"mappings":"AAAA,WAAW;AACX;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,wCAAwC;EACxC,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;AACA;EACE,eAAe;EACf,4CAA4C;AAC9C;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,OAAO;EACP,QAAQ;EACR,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;AACA;EACE,6BAA6B;EAC7B,YAAY;EACZ,WAAW;EACX,eAAe;AACjB;AACA;EACE,WAAW;AACb;AACA;EACE,6BAA6B;EAC7B,YAAY;EACZ,cAAc;EACd,eAAe;EACf,aAAa;AACf;;AAEA;EACE,aAAa;AACf;AACA,eAAe","sourcesContent":["/* Header */\n.header-row {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.logo-box a {\n  display: block;\n}\n\n.logo-box a img {\n  width: 100%;\n  max-width: 109px;\n}\n.main-menu {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.main-menu li a {\n  font-size: 17.6px;\n  font-weight: 600;\n  margin: 0 12px 0;\n  color: #666666d9;\n}\n.main-menu li {\n  border-right: 1px solid rgb(0 0 0 / 10%);\n  line-height: 18px;\n}\n\na {\n  cursor: pointer;\n}\n.main-menu li:last-child {\n  border-right: none;\n}\n.position-header .main-menu li a {\n  color: #fff;\n}\nheader {\n  padding: 13px 0;\n  border-bottom: 1px solid rgb(34 34 34 / 10%);\n}\n.position-header {\n  position: absolute;\n  z-index: 999;\n  left: 0;\n  right: 0;\n  margin-top: 5px;\n  border-bottom: none;\n}\nheader .container-fluid {\n  padding: 0 50px;\n}\n.toggle-btn button {\n  background-color: transparent;\n  border: none;\n  color: #222;\n  font-size: 25px;\n}\n.position-header .toggle-btn button {\n  color: #fff;\n}\n.cross-btn {\n  background-color: transparent;\n  border: none;\n  color: #666666;\n  font-size: 25px;\n  display: none;\n}\n\n.toggle-btn {\n  display: none;\n}\n/* End Header */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
