import "./footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        {/* <p>Copyright 2024 © NemeaGov</p> */}
        <p>Copyright 2024 © MyCityConnect</p>
      </div>
    </footer>
  );
};

export default Footer;
