import React, { useEffect, useState } from "react";
import Slider from "react-slick";
// import { Link } from "react-router-dom";

import "./home.css";

import { fetchHomePage } from "../../Services/DataService";

import Citizen from "../../Assets/images/citizen-img.jpg";
import DashIcon from "../../Assets/images/dash-icon.svg";
// import Srv1 from "../../Assets/images/srv-1.jpg";
// import Srv2 from "../../Assets/images/srv-2.jpg";
// import Srv3 from "../../Assets/images/srv-3.jpg";
// import ChevronRight from "../../Assets/images/chevron-right.svg";
import Modern1 from "../../Assets/images/morden-1.jpg";
import RealTime from "../../Assets/images/real-time.png";
import UrbanLogo from "../../Assets/images/urban-logo.svg";
import Communication from "../../Assets/images/communication.png";
import Star from "../../Assets/images/star.svg";

import Header from "../../Components/Header/Header";
// import NewsSection from "../../Components/News/NewsSection";
import Footer from "../../Components/Footer/Footer";

function MainPage() {
  const [homepage, setHomepage] = useState({
    poisCount: 0,
    announcementsCount: 0,
    eventsCount: 0,
    latestAnnouncements: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    focusOnSelect: false,
  };

  useEffect(() => {
    const fetchData = async () => {
      setHomepage(await fetchHomePage());
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      <Header headerClass="position-header" />
      <main role="main">
        <div className="hero-main">
          <Slider {...settings} className="hero-slider">
            <div className="items-slide">
              <div className="container">
                <div className="hero-inner">
                  <h1>
                    {/* Nemea<span className="primary-color">Gov</span> */}
                    My City Connect
                  </h1>
                  <p>
                    Ψηφιακή Πλατφόρμα Ολοκληρωμένων & Απομακρυσμένων Ψηφιακών
                    Υπηρεσιών προς τους Πολίτες και τις Επιχειρήσεις του Δήμου
                    Νεμέας
                  </p>
                </div>
              </div>
            </div>
            <div className="items-slide">
              <div className="container">
                <div className="hero-inner">
                  <h1>
                    {/* Nemea<span className="primary-color">Gov</span> */}
                    My City Connect
                  </h1>
                  <p>
                    Ψηφιακή Πλατφόρμα Ολοκληρωμένων & Απομακρυσμένων Ψηφιακών
                    Υπηρεσιών προς τους Πολίτες και τις Επιχειρήσεις του Δήμου
                    Νεμέας
                  </p>
                </div>
              </div>
            </div>
          </Slider>
          <div className="hero-counter">
            <div className="row">
              <div className="col4">
                <div className="hr-count">
                  <h2>{homepage.poisCount}</h2>
                  <p>Σημεία ενδιαφέροντος</p>
                </div>
              </div>
              <div className="col4">
                <div className="hr-count">
                  <h2>{homepage.announcementsCount}</h2>
                  <p>Αναφορές</p>
                </div>
              </div>
              <div className="col4">
                <div className="hr-count">
                  <h2>{homepage.eventsCount}</h2>
                  <p>Εκδηλώσεις</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="platform-section">
          <div className="container">
            <div className="platform-inner">
              <p>
                Αναπτύχθηκε Ψηφιακή Πλατφόρμα Ολοκληρωμένων & Απομακρυσμένων
                Ψηφιακών Υπηρεσιών προς τους Πολίτες και τις Επιχειρήσεις του
                Δήμου
              </p>
              <h3>
                Νεμέας –{" "}
                <strong>
                  {/* Nemea<span className="primary-color">Gov</span> */}
                  My City Connect
                </strong>
              </h3>
            </div>
          </div>
        </div>

        <div className="citizen-section">
          <div className="row">
            <div className="col6">
              <div className="citizen-left">
                <h6>ΣΤΟ ΕΠΙΚΕΝΤΡΟ Ο ΠΟΛΙΤΗΣ</h6>
                <h2>Ψηφιακές υπηρεσίες εξυπηρέτησης Δημότη</h2>
                <p>
                  καταφέραμε να δημιουργήσουμε εφαρμογές που χαρακτηρίζονται από
                  ευελιξία, ταχύτητα αλλά και εύκολη διαχείριση
                </p>
              </div>
            </div>
            <div className="col6">
              <div className="citizen-right">
                <img src={Citizen} alt="" />
                <div className="citizen-box">
                  <div className="row">
                    <div className="col6">
                      <div className="citizen-bx-inner">
                        <p>Εύκολη Διαχείριση</p>
                      </div>
                    </div>
                    <div className="col6">
                      <div className="citizen-bx-inner">
                        <p>Ισχυρό Reporting</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="digital-section">
          <div className="container">
            <div className="digital-inner">
              <div className="sc-title">
                <h3>Καλώς ήρθατε σε έναν κόσμο ψηφιακής πρόοδου</h3>
                <p>
                  Οι Ψηφιακές Υπηρεσίες Εξυπηρέτησης Δημότη, το Σύστημα
                  Αποτύπωσης, Οργάνωσης και Διαχείρισης Ακίνητης Περιουσίας και
                  Περιουσιακών Μονάδων, καθώς και η Πλατφόρμα Τηλεκπαίδευσης,
                  αποτελούν πλήρως λειτουργικούς πόρους που ολοκληρώνουν την
                  ψηφιακή μας προσφορά και είναι πλέον στη διάθεση των πολιτών.
                </p>
              </div>
            </div>
            <div className="digital-grid">
              <div className="dil-grid">
                <img src={DashIcon} alt="" />
                <p>Εφαρμογή Υποβολής και Διαχείρισης Αιτημάτων Πολιτών</p>
              </div>
              <div className="dil-grid">
                <img src={DashIcon} alt="" />
                <p>Εφαρμογή Ηλεκτρονικών Ραντεβού</p>
              </div>
              <div className="dil-grid">
                <img src={DashIcon} alt="" />
                <p>
                  Εφαρμογή Έκδοσης Ψηφιακών Πιστοποιητικών και Διοικητικών
                  Πράξεων
                </p>
              </div>
              <div className="dil-grid">
                <img src={DashIcon} alt="" />
                <p>
                  Σύστημα Αποτύπωσης, Οργάνωσης και Διαχείρισης Ακίνητης
                  Περιουσίας και Περιουσιακών Μονάδων του Δήμου
                </p>
              </div>
              <div className="dil-grid">
                <img src={DashIcon} alt="" />
                <p>
                  Πλατφόρμα Τηλεκπαίδευσης και Εξοπλισμός για εκσυγχρονισμό της
                  μαθησιακής διαδικασίας και της εξ αποστάσεως εκπαίδευσης
                </p>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="service-section" id="service-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col4">
                <div className="srv-inner">
                  <div className="srv-img">
                    <img src={Srv1} alt="" />
                  </div>
                  <div className="srv-content">
                    <h6>Ψηφιακές Υπηρεσίες</h6>
                    <h3>Εξυπηρέτηση Δημότη</h3>
                    <a className="fill-button" href="#">
                      Μετάβαση
                    </a>
                  </div>
                </div>
              </div>
              <div className="col4">
                <div className="srv-inner">
                  <div className="srv-img">
                    <img src={Srv2} alt="" />
                  </div>
                  <div className="srv-content">
                    <h6>Ψηφιακές Υπηρεσίες</h6>
                    <h3>
                      Σύστημα Αποτύπωσης ακινήτων και περιουσίας του Δήμου
                    </h3>
                    <a className="fill-button" href="#">
                      Μετάβαση
                    </a>
                  </div>
                </div>
              </div>
              <div className="col4">
                <div className="srv-inner">
                  <div className="srv-img">
                    <img src={Srv3} alt="" />
                  </div>
                  <div className="srv-content">
                    <h6>Ψηφιακές Υπηρεσίες</h6>
                    <h3>Πλατφόρμα Τηλεκπαίδευσης και εξοπλισμού</h3>
                    <a className="fill-button" href="#">
                      Μετάβαση
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="morden-service">
          <div className="container">
            <div className="sc-title">
              <h3>Σύγχρονη Εξυπηρέτηση για Ενεργούς Πολίτες!</h3>
              <p>
                Ανακαλύψτε ένα νέο εποχής άνεσης και αποτελεσματικότητας με τις
                προηγμένες ψηφιακές υπηρεσίες του δήμου μας! Από υπηρεσίες προς
                τους πολίτες έως τη διαχείριση ακινήτων και διαδραστικές
                πλατφόρμες ηλεκτρονικής εκπαίδευσης,
              </p>
              <hr></hr>
            </div>
          </div>
          <div className="morden-inner">
            <div className="row">
              <div className="col6">
                <div className="morden-content">
                  <h2 className="with-shape">
                    Έξυπνες
                    <br /> κοινότητες
                  </h2>
                  <h2>
                    δραστήριοι
                    <br /> πολίτες
                  </h2>
                </div>
              </div>
              <div className="col6">
                <div className="morden-img">
                  <img src={Modern1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="urban-service light-grey-bg" id="urban-service">
          <div className="container">
            <div className="urban-title">
              <img src={UrbanLogo} alt="" />
              <p>
                Διαχειριστείτε όλα τα αιτήματα σας μέσα από ένα ενιαίο σύστημα,
                δρομολογήστε αυτόματα την επίλυση των αιτημάτων προς την αρμόδια
                υπηρεσία εξοικονομώντας χρόνο και βελτιώνοντας την
                καθημερινότητα σας.
              </p>
            </div>
            <div className="urban-main">
              <div className="urban-row">
                <div className="row">
                  <div className="col6">
                    <div className="urban-img">
                      <img src={Communication} alt="" />
                    </div>
                  </div>
                  <div className="col6">
                    <div className="urban-content">
                      <h2>Άμεση επικοινωνία</h2>
                      <p>
                        Τα αιτήματα των πολιτών ανατίθενται απευθείας στην
                        αρμόδια δημοτική υπηρεσία,
                      </p>
                      <div className="urb-rw">
                        <div className="urb-icon">
                          <img src={DashIcon} alt="" />
                        </div>
                        <div className="urb-text">
                          <h3>Διαχείριση αιτημάτων & διαδραστικοί χάρτες</h3>
                          <p>
                            Η εφαρμογή επιτρέπει στους Δημότες να αναφέρουν
                            γρήγορα και εύκολα τα προβλήματα που συναντούν στην
                            καθημερινότητά τους (π.χ. λακκούβες, καμένες λάμπες,
                            ογκώδη αντικείμενα κ.α.) μέσω της δωρεάν εφαρμογής
                            για κινητά τηλέφωνα (mobile app), καθώς και να
                            λαμβάνουν αυτοματοποιημένες ειδοποιήσεις σχετικά με
                            την πορεία επίλυσης του αιτήματός τους.
                          </p>
                        </div>
                      </div>
                      <div className="urb-rw">
                        <div className="urb-icon">
                          <img src={DashIcon} alt="" />
                        </div>
                        <div className="urb-text">
                          <h3>
                            Διαχείριση, ανταλλαγή και προγραμματισμός αποκομιδής
                            ογκωδών αντικειμένων
                          </h3>
                          <p>
                            Η εφαρμογή παρέχει τη δυνατότητα υποβολής αιτήματος
                            από τους πολίτες για την αποκομιδή ογκωδών
                            αντικειμένων από τον Δήμο, με παράλληλο
                            προγραμματισμό της αποκομιδής από την αρμόδια
                            υπηρεσία.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="urban-row">
                <div className="row">
                  <div className="col6">
                    <div className="urban-content">
                      <h2>Ενημέρωση σε πραγματικό χρόνο</h2>
                      <p>
                        Νέα, ανακοινώσεις και διαχείρηση των εκδηλώσεων
                        πραγματοποιούνται άμεσα,
                      </p>
                      <div className="urb-rw">
                        <div className="urb-icon">
                          <img src={DashIcon} alt="" />
                        </div>
                        <div className="urb-text">
                          <h3>
                            Διαχείριση σημείων ενδιαφέροντος, δράσεων &
                            εκδηλώσεων
                          </h3>
                          <p>
                            Σε δυναμικό χάρτη της πόλης, ο δημότης έχει τη
                            δυνατότητα να εμφανίσει με χρήση φίλτρων σημεία
                            ενδιαφέροντος, όπως εμπορικά καταστήματα, φαρμακεία,
                            δημόσια κτίρια, σημεία φόρτισης ηλεκτρικών οχημάτων,
                            μνημεία, μουσεία, σημεία ανακύκλωσης, κ.ά.
                          </p>
                        </div>
                      </div>
                      <div className="urb-rw">
                        <div className="urb-icon">
                          <img src={DashIcon} alt="" />
                        </div>
                        <div className="urb-text">
                          <h3>
                            Ανάρτηση τελευταίων νέων της πόλης και ανακοινώσεις
                            σε πραγματικό χρόνο
                          </h3>
                          <p>
                            Η εφαρμογή παρέχει τη δυνατότητα αναρτήσεω των
                            τελευταίων νέων, ανακοινώσεων καθώς και εκτάκτων
                            ειδοποιήσεων σε πραγματικό χρόνο.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col6">
                    <div className="urban-img">
                      <img src={RealTime} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="download-app" id="download-app">
          <div className="container">
            <div className="download-inner">
              <h3>Κατεβάστε την εφαρμογή</h3>
              <div className="buttons-grp">
                <a className="fill-button" href="#">
                  App Store
                </a>
                <a className="fill-button dark-grey-btn" href="#">
                  Google Play
                </a>
              </div>
            </div>
          </div>
        </div> */}

        <div className="opinions-section green-bg" id="opinions-section">
          <div className="container">
            <div className="opinions-title">
              <h3>Απόψεις δημοτών</h3>
              <p>Στείλε μας την γνώμη και τις προτάσεις σου για την πόλη μας</p>
            </div>
            <div className="opinions-inner">
              <div className="opinions-bx">
                <img src={Star} alt="" />
                <p>
                  Εντυπωσιασμένοι από την ευκολία χρήσης της πλατφόρμας, οι
                  πολίτες αντιλαμβάνονται την εφαρμογή ως έναν πολύτιμο εργαλείο
                  για την επικοινωνία τους με τις τοπικές αρχές.
                </p>
              </div>
              <div className="opinions-bx">
                <img src={Star} alt="" />
                <p>
                  Μια πρωτοποριακή λύση που διευκολύνει την αλληλεπίδραση μεταξύ
                  των πολιτών και των τοπικών αρχών
                </p>
              </div>
              <div className="opinions-bx">
                <img src={Star} alt="" />
                <p>
                  Παρέχει άμεση πρόσβαση στο δήμο, επιτρέποντας στους πολίτες να
                  αναφέρουν προβλήματα και να υποβάλλουν αιτήματα με άνεση.
                </p>
              </div>
              <div className="opinions-bx">
                <img src={Star} alt="" />
                <p>Συμβάλλει στην ποιοτική βελτίωση της ζωής στις πόλεις</p>
              </div>
              <div className="opinions-bx">
                <img src={Star} alt="" />
                <p>Εύκολη περιήγηση και απλότητα χρήσης της πλατφόρμας</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="news-section" id="news-section">
          <div className="container">
            <div className="news-title">
              <h2>
                <span>Νέα και ανακοινώσεις του Δήμου</span>
              </h2>
            </div>
            <NewsSection
              newsClass="news-inner"
              news={homepage.latestAnnouncements}
              isLoading={isLoading}
            />
            <div className="show-more">
              <Link className="fill-button" to={"/News"}>
                Όλα τα Νέα
              </Link>
            </div>
          </div>
        </div> */}

        <div className="ctc-section">
          <div className="container">
            <div className="ctc-inner">
              <p>
                Για οποιαδήποτε ερώτηση, πληροφορία ή ανάγκη για υποστήριξη,
                μπορείτε να επικοινωνήσετε μαζί μας.
              </p>
              {/* <h3>+30 210123456</h3> */}
              <h3>+30 2106541942</h3>
              {/* <h4>Info@nemeaGov.gr</h4> */}
              <h4>Info@MyCityConnect.gr</h4>
              {/* <a href="#">
                Επικοινωνία <img src={ChevronRight} />
              </a> */}
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}

export default MainPage;
